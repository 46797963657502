import React from "react";
import styled from "styled-components";
import Logo from "@assets/logo.png";

function NavBar({ option }) {
  const onScroll = (direction) => {
    const scroll = document.querySelector(direction);
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Wrapper>
      <div className="home">
        <img className="Logo" src={Logo} alt="logo" />
      </div>
      <ul className="menu">
        <li itemName="main" onClick={() => onScroll(".main")}>
          메인
        </li>
        <li itemName="intro" onClick={() => onScroll(".intro")}>
          소개
        </li>
        <li itemName="process" onClick={() => onScroll(".process")}>
          과정
        </li>
        <li itemName="after" onClick={() => onScroll(".after")}>
          후기
        </li>
        <li itemName="qna" onClick={() => onScroll(".apply")}>
          문의
        </li>
      </ul>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 60px;
  color: white;
  font-family: "NotoSansKR-Medium";
  font-weight: 700;
  z-index: 800;
  // left menu
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    .Logo {
      width: 40px;
      height: 40px;
      margin-left: 40px;
    }
    div {
      margin: 10px;
    }
  }
  // right menu
  .menu {
    padding: 0;
    display: flex;
    list-style: none;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    li {
      margin: 20px;
      &:hover {
        color: white;
      }
    }
    li + li {
      margin-left: 20px;
    }
  }
  // media query
  @media only screen and(min-width:768px) and (max-width: 1199px) {
    // tablet
  }
  @media only screen and (min-width: 480px) and(max-width:767px) {
    // mobile 가로
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    font-size: 12px;
    .home {
      .Logo {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
      div {
        font-size: 10px;
        margin: 10px;
      }
    }
    .menu {
      li {
        margin: 10px;
      }
    }
  }
`;

export default NavBar;
// @media only screen and (max-width: 1200px) {
//   // tablet
// }
// @media only screen and (max-width:767px) {
//   // mobile 가로
// }
// @media only screen and (max-width: 479px) {
//   // mobile 세로
// }
