import React from "react";
import styled from "styled-components";
import Banner from "@assets/banner.jpg";

function Main({ content }) {
  return (
    <Wrapper className="main" id="main">
      <img className="main-image" src={Banner} alt="main-image" />
      <div className="main-comment">
        <div className="comment">
          <p className="highlight">특별한</p>
          <p className="highlight black">오늘을</p>
          <p className="highlight black">듣다.</p>
        </div>
        <p className="comment-small">전문 작곡가들의 음원 제작부터 유통까지</p>
        <p className="comment-small">잊을 수 없는 특별한 하루를 만들어보세요</p>
        <ul className="comment-list">
          <li>작곡/작사</li>
          <label>|</label>
          <li>보컬트레이닝</li>
          <label>|</label>
          <li>믹싱/마스터링</li>
        </ul>
      </div>

      <div
        className="main-button"
        onClick={() => {
          const scroll = document.querySelector(".apply");
          scroll.scrollIntoView({ behavior: "smooth" });
        }}
      >
        신청하기
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  height: 800px;
  .main-image {
    width: 100%;
    height: 800px;
    object-fit: cover;
    filter: brightness(1.2);
    z-index: 1;
  }
  .main-comment {
    position: absolute;
    top: 210px;
    left: calc(120px + 7%);
    width: calc(7% + 330px);
    height: 500px;
    border-radius: 50px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    .highlight {
      color: orange;
      font-size: 72px;
      font-weight: 700;
      margin: 0;
      text-align: left;
      margin-left: 48px;
    }
    .black {
      color: black;
    }
    .comment {
      margin-top: 60px;
    }
    .comment-small {
      font-size: 1.1rem;
      text-align: left;
      margin: 20px 0 1px 48px;
    }
    .comment-small + .comment-small {
      margin-top: 1px;
    }
  }
  .comment-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: 48px;
    margin-top: 12px;
    font-family: NanumBold;
    label {
      color: orange;
      font-weight: 700;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  .main-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "NotoSansKR-Medium";
    top: calc(120px + 35vh);
    right: 100px;
    width: 300px;
    height: 80px;
    background: rgba(255, 165, 0, 0.7);
    color: white;
    font-weight: 600;
    font-size: 2.5rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 165, 0, 0.9);
    }
  }
  // media query
  @media only screen and (max-width: 1200px) {
    // tablet
    height: auto;
    .main-comment {
      width: 400px;
      left: 60px;
    }
    .main-button {
      top: 70%;
      right: 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: auto;
    .main-image {
      height: auto;
    }
    .main-comment {
      top: 13%;
      left: 70px;
      width: 320px;
      height: 320px;
      .highlight {
        font-size: 40px;
      }
      .comment-small {
        font-size: 12px;
      }
    }
    .comment-list {
      font-size: 13px;
    }
    .main-button {
      top: 62%;
      right: 5%;
      font-size: 20px;
      width: 150px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 479px) {
    height: auto;
    // mobile 세로
    .main-image {
      height: auto;
    }
    .main-comment {
      position: relative;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100%;
      height: 350px;
      margin-top: 30px;
      .highlight {
        font-size: 50px;
      }
      .comment {
        margin-top: 0;
      }
      .comment-small {
        font-size: 15px;
      }
    }
    .main-button {
      position: relative;
      top: 0;
      left: 25%;
      width: 50%;
      height: 60px;
      margin-bottom: 20px;
    }
  }
`;

export default Main;
